@font-face {
  font-family: MainFont;
  src: url("../../../public/fonts/Font.otf") format("opentype");
}

.soon_container {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: relative;
  background-color: rgb(64, 86, 158);
  color: rgb(212, 233, 238);
  font-family: MainFont;
  box-shadow: inset 50px 50px 100px rgb(48, 57, 61);
}

.soon_background {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: inherit;
  height: inherit;
  opacity: 0.25;
  object-fit: cover;
}

.soon_content_wrapper {
  display: flex;
  position: relative;
  top: 0;
  bottom: 0;
  filter: drop-shadow(0px 0px 15px black);
}

.soon_corner {
  position: absolute;
  border-color: transparent transparent transparent rgba(39, 143, 161, 0.651);
  border-style: solid;
  border-width: 0rem 0rem 75rem 75rem;
  height: 0px;
  width: 0px;
  width: 25%;
  z-index: -1;
}

.logo {
  width: 15rem;
  margin-top: 5rem;
  filter: drop-shadow(0px 0px 25px black);
}

.soon_main_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(75rem - 125%);
}

.soon_main_wrapper > h4 {
  color: rgba(240, 248, 255, 0.849);
  border-bottom: 1px solid rgba(240, 248, 255, 0.39);
  padding: 0 5px 2px 5px;
}

.soon_form {
  display: flex;
  margin: 0.5rem 0;
}

.soon_input {
  margin-right: 1rem;
  border: none;
  border-radius: 5px;
  background: rgba(50, 197, 210, 0.418);
  filter: drop-shadow(0px 0px 5px black);
  color: aliceblue;
  padding: 5px;
}

.soon_input::placeholder {
  color: rgba(240, 248, 255, 0.63);
}

.soon_btn {
  background: hsla(185, 64%, 51%, 1);

  background: linear-gradient(90deg, hsla(185, 64%, 51%, 0.7) 0%, hsla(277, 74%, 24%, 0.7) 100%);

  background: -moz-linear-gradient(90deg, hsla(185, 64%, 51%, 0.7) 0%, hsla(277, 74%, 24%, 0.7) 100%);

  background: -webkit-linear-gradient(90deg, hsla(185, 64%, 51%, 0.7) 0%, hsla(277, 74%, 24%, 0.7) 100%);

  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#30C5D2", endColorstr="#471069", GradientType=1 );
  color: aliceblue;
  border: none;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  font-family: MainFont;
  filter: drop-shadow(0px 0px 5px black);
}

.soon_btn:hover {
  cursor: pointer;
  filter: drop-shadow(0px 0px 10px black);
}

/* Mobile-specific styles */
@media (max-width: 768px) {
  .soon_main_wrapper {
    width: 100wv;
    font-size: small;
    justify-content: center;
    transform: translateX(-25%);
  }

  .logo {
    width: 10rem;
    margin-top: 3rem;
  }

  .soon_corner {
    border-width: 0rem 0rem 45rem 45rem;
    opacity: 0;
  }

  .soon_input {
    margin-right: 0.5rem;
    padding: 3px;
  }
}
